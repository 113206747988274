import React, { useState, useRef } from 'react'
import Navbar from '../components/ContactNavbar.jsx'
import FrostEffect from '../components/FrostEffectSecond'
import "aos/dist/aos.css";
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import Reveal from '../components/Reveal.tsx';
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";

function Catering() {

  const [isSubmit, setIsSubmit] = useState(false);
  const formRef = useRef(null);

  const navigate = useNavigate(); // Access the history object for navigation

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    const formData = new FormData(event.target); // Get form data

    try {
      // Send form data to Netlify form submission endpoint
      await fetch('/', {
        method: 'POST',
        body: formData,
      });

      setIsSubmit(true);
      formRef.current.reset();
      setTimeout(() => {
        setIsSubmit(false)
      }, 5000);

    } catch (error) {
      console.error('Form submission error:', error);
      // Handle error (e.g., display error message)
    }
  };

  return (
    <div>
      <Navbar />
      <FrostEffect />
      <div className="md:pt-24 pt-16 flex flex-col items-center w-full fixed z-10">
        <div className={`success font-body bg-[#4a934a]/80 p-3 md:p-12 text-center text-white w-[80%] md:w-[50%] md:text-2xl backdrop-blur-md rounded-x ease-in-out duration-500 rounded-xl ${isSubmit ? "translate-y-[0rem]" : "translate-y-[-50rem]"}`} id="success">
          Úspěšně jste odeslali formulář. děkujeme, že jste nás kontaktovali. Na vaši zprávu odpovíme e-mailem, jakmile to bude možné.
        </div>
      </div>
      <div className="flex flex-col xl:flex-row pt-24 md:py-36 px-6 lg:px-16 xl:px-32 items-center xl:translate-x-0">
        <div className="pt-6 pb-16 px-6 bg-white rounded-xl text-center flex flex-col gap-6 items-center w-[85%] md:w-[85%] xl:w-[100%] md:pb-[6rem] md:pt-[6rem] md:px-[3rem]">
          <div className="flex flex-col md:gap-3">
            <h1 className="font-header text-2xl md:text-6xl">Kontaktujte Nás</h1>
            <p className="font-body text-md md:text-3xl md:mx-20">Neváhejte nás kdykoli kontaktovat. Ozveme se vám co nejdříve.</p>
          </div>
          <div>
            <form ref={formRef} name="ContactUs" method="POST" data-netlify="true" netlify-honeypot="bot-field" className="flex flex-col gap-8 md:gap-16 items-center" onSubmit={handleSubmit}>
              <input type="hidden" name="form-name" value="ContactUs" />
              <input type="text" required name="name" className="border-b-2 md:text-3xl text-center font-body font-bold pb-3" placeholder="Jméno" />
              <input type="email" required name="email" className="border-b-2 md:text-3xl text-center font-body font-bold pb-3" placeholder="Email" />
              <textarea required name="message" className="border-b-2 md:text-3xl text-center font-body font-bold pb-3" placeholder='Zpráva'></textarea>
              <input type="submit" value="Submit" className="font-body p-2 w-[50%] bg-yellow text-white font-bold rounded-md md:text-xl"></input>
            </form>
          </div>
        </div>
        <div className="flex flex-col md:py-20 bg-yellow xl:translate-x-[-3rem] translate-y-[-3rem] xl:translate-y-0 p-6 w-full rounded-xl md:gap-10 gap-3">
          <h1 className="font-header text-2xl md:text-6xl text-center text-white">Kontakt</h1>
          <div className="flex flex-col text-white gap-3 font-body md:gap-6 items-center">
            <h2 className="font-body font-black text-white text-center md:text-5xl text-2xl">Pochutnej Si! BISTRO</h2>
            <div className="flex flex-col md:gap-6">
              <div className="flex flex-row gap-6 md:text-3xl">
                <FaLocationDot />
                <p>Lidická 544, 739 61 Třinec</p>
              </div>
              <div className="flex flex-row gap-6 md:text-3xl">
                <FaPhone />
                <p>+420 775 932 273</p>
              </div>
              <div className="flex flex-row gap-6 md:text-3xl">
                <IoIosMail />
                <p>bistro@pochutnejsi.cz</p>
              </div>
              <div className='flex flex-col gap-1 text-center md:text-xl'>
              Objednávka cateringu:
                <div className="flex flex-row gap-6 md:text-3xl">
                  <IoIosMail />
                  <p>catering@pochutnejsi.cz</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col text-white font-body text-center md:text-xl py-6 text-sm">
            <p>
              Provozovatel<br></br>
              Enaxa s.r.o.<br></br>
              Lidická 544, 739 61 Třinec<br></br>
              IČ 28660820

            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Catering